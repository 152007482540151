/**
 * Line-Up calendar widget loader
 *
 * This is a lightweight app that should not be cached, it loads the actual
 * ticketing application by looking for the embed code tags on the page.
 *
 * An instance of the LineupTicketingLoader class is exposed via the DOM, so
 * that the ticketing app can be programatically started if needed.
 *
 */
import domready from "domready";

const IFRAME_URL = process.env.CALENDAR_IFRAME_URL || "";
class LineupCalendarLoader {
  constructor() {
    this.widgetCount = 0;
    this.renderedClassName = "line-up-calendar-rendered";
    this.baseId = "line-up-calendar-embed-";
    this.basePayloadId = "line-up-calendar-payload-";
    this.loaded = false;
    this.luClassName = "line-up-calendar";
    this.styleTagAdded = false;
    this.initWidget();
  }

  initWidget() {
    if (this.loaded) {
      return;
    }

    this.loaded = true;
    domready(() => this.setupWidgets());
  }

  /**
   * Find all lineup-ticketing tags on the page and setup the iframe
   *
   */
  setupWidgets() {
    let widgets = document.getElementsByClassName(this.luClassName);

    // current lineup-ticketing div embed code
    for (let idx = 0; idx < widgets.length; idx++) {
      let widget = widgets[idx];
      if (
        widget.tagName === "DIV" &&
        widget.getAttribute("data-line-up-api-key") !== null
      ) {
        let options = this.parseOptions(widget);
        this.insertIFrame(widget, options);
      }
    }
  }

  /**
   * Convert a object with key-value pairs to a url encoded string
   *
   */
  serialize(obj) {
    let str = [];
    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  /**
   * Parse the data-* attributes and return them as a key-value object
   *
   */
  parseOptions(widget) {
    const apiKey = widget.getAttribute("data-line-up-api-key");
    const width = widget.getAttribute("data-line-up-width") || "100%";
    const height = widget.getAttribute("data-line-up-height") || "100%";
    const mainColor =
      widget.getAttribute("data-line-up-main-color") || "#32898f";
    const bgColor =
      widget.getAttribute("data-line-up-background-color") || "#ffffff";
    let options = {
      apiKey: apiKey,
      height: height,
      width: width,
      mainColor: mainColor,
      backgroundColor: bgColor,
    };

    // remove any null values from the options list
    for (let key in options) {
      if (options[key] === null) {
        delete options[key];
      }
    }

    return options;
  }

  /**
   * Create the iframe and embed it into the page
   *
   */
  insertIFrame(widget, options) {
    const widgetNumber = this.widgetCount++;
    const widgetId = this.baseId + widgetNumber;
    const iframe = document.createElement("iframe");
    if (!widget.id) {
      widget.id = this.basePayloadId + widgetNumber;
    }

    iframe.className = widget.className;
    iframe.id = widgetId;
    iframe.src = IFRAME_URL + "?" + this.serialize(options);
    iframe.setAttribute("allowTransparency", true);
    iframe.setAttribute("frameBorder", 0);
    iframe.setAttribute("height", options.height);
    iframe.setAttribute("width", options.width);
    widget.appendChild(iframe);
    widget.className = this.renderedClassName;
  }

  startIFrameApplication = (elementId, options) => {
    const rootNode = document.getElementById(elementId);
    if (!rootNode) {
      throw new Error("Could not find element to attach to");
    }

    this.insertIFrame(rootNode, options);
  };

  stopIFrameApplication = (elementId) => {
    const rootNode = document.getElementById(elementId);
    if (!rootNode) {
      return false;
    }

    const iframeNode = rootNode.getElementsByTagName("iframe")[0];
    if (!iframeNode) {
      return false;
    }

    rootNode.removeChild(iframeNode);
    return true;
  };
}

if (!window.LineupCalendarLoader) {
  window.LineupCalendarLoader = new LineupCalendarLoader();
}
